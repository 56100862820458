window.addEventListener('DOMContentLoaded', () => {
  // _debug toggles
  document.querySelectorAll('[data-debug-toggle]').forEach((toggle) => {
    toggle.addEventListener('click', (e) => {
      const content = document.querySelector(`[data-debug-content='${toggle.dataset.debugToggle}']`);

      if (content.dataset.visible == 'true') {
        content.style.display = 'none';
        content.dataset.visible = null;
        toggle.classList.add('solo');
      } else {
        content.style.display = null;
        content.dataset.visible = 'true';
        toggle.classList.remove('solo');
      }

      e.preventDefault();
    });
  });
});
